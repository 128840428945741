  
.report-style-class {
	height: 100vh;
	margin: 0% auto;
	width: 100%;
}
iframe {
    border: none;
}

