body{   
    background-color: #272b3d;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.DivScrollLeft{   
    overflow-y:auto;
    overflow-x:hidden;
    max-height: calc(100%);
    padding-bottom: 10px;
    position:fixed;
    white-space:nowrap;  
    width:270px;
    background-color:#181a22E0;
    left:0px;
    top:0px; 
}

.DivScrollLeft::-webkit-scrollbar {
    display: none;
  }

.DivScrollParent{   
    overflow-y:auto;
    overflow-x:hidden;
    max-height: calc(100%);
    padding-bottom: 10px;
    position:fixed;
    white-space:nowrap;  
    width:270px;
    background-color:#181a22E0;
    right:0px;
    top:0px;
   
}

.DivScrollParent::-webkit-scrollbar {
    display: none;
  }

.ant-collapse-content-box{
padding:0px !important;
  }
.insideCollapse{
    padding:16px;
    background-color:#17181bde;

  }